
import { FormikHelpers, useFormik } from "formik";
import { useNavigate } from "react-router-dom";
import { SuccessStoryCreate } from "../successStory";
import { Grid, TextField, Box, FormHelperText, Paper, Button } from "@mui/material";


type SuccessStoryProps = {
  onSubmit: (
    value: SuccessStoryCreate,
    helpers: FormikHelpers<SuccessStoryCreate>
  ) => Promise<boolean>;
  submitting: boolean;
  
};

const CreateSuccessStoryForm = ({
  onSubmit,
  submitting,
  
}: SuccessStoryProps) => {
  const navigate = useNavigate();

  const handleSubmit = async (
    value: SuccessStoryCreate,
    helpers: FormikHelpers<SuccessStoryCreate>
  ) => {
    const success = await onSubmit(value, helpers);
    if (success) {
      navigate('/', { replace: true });
      navigate('/success-stories/create', { replace: true });
    };
  };

  const initialValues: SuccessStoryCreate =  {    title: '',
    description: '',
    cover: null,
}

  const formik = useFormik({
    initialValues,
    onSubmit: handleSubmit,
  });

  // console.log('Edit Form', initialValues);

  return (
    
<>
  <Box sx={{ flexGrow: 1 }}>
  <form onSubmit={formik.handleSubmit}>
    <Grid container spacing={1}>
    
<Grid item xs={12} sm={9}>
  <Paper sx={{ p: 2, pb: 3 }}>
  <Grid container justifyContent="space-between" spacing={1} sx={{ mt: 2 }}>
<Grid item alignSelf="flex-start" sx={{ flexGrow: 1 }}>
  <TextField
    fullWidth
    name="title"
    size="small"
    type="text"
    variant="outlined"
    label="Title"
    placeholder=""
    value={formik.values.title}
    onChange={formik.handleChange}
    error={Boolean(formik.errors.title)}
    helperText={formik.errors.title}
  />
</Grid>

<Grid item sx={{ flexGrow: 1 }}></Grid>
</Grid><Grid container justifyContent="space-between" spacing={1} sx={{ mt: 2 }}>
<Grid item alignSelf="flex-start" sx={{ flexGrow: 1 }}>
  <TextField
    fullWidth
    name="description"
    size="small"
    type="text"
    variant="outlined"
    label="Description"
    placeholder=""
    value={formik.values.description}
    onChange={formik.handleChange}
    error={Boolean(formik.errors.description)}
    helperText={formik.errors.description}
    multiline
    rows={4}
  />
</Grid>
</Grid><Grid container justifyContent="space-between" spacing={1} sx={{ mt: 2 }}>
<Grid item alignSelf="flex-start" sx={{ flexGrow: 1 }}>
  <Grid item xs={12}>
    Cover Image
  </Grid>
  <Box>
    <input
      type="file"
      onChange={({ target: { files } }) => {
        formik.setFieldValue(
          'cover',
          files && files.length ? files[0] : null
        );
      }}
      name="cover"
    />
  </Box>
  {formik.touched.cover && formik.errors.cover && (
    <FormHelperText error>
      {formik.errors.cover}
    </FormHelperText>
  )}
</Grid>

<Grid item sx={{ flexGrow: 2 }}></Grid>
</Grid>
  </Paper>
</Grid>

    
<Grid item xs={12} sm={3}>
  <Paper sx={{ p: 2 }}>
    <Grid container>
      <Button
        type="submit"
        variant="contained"
        disabled={submitting}
        size="small"
      >
        {submitting ? 'Saving' : 'Save'}
      </Button>
    </Grid>
  </Paper>
</Grid>

    </Grid>
    </form>
  </Box>
</>
  
  );
};

export default CreateSuccessStoryForm;
