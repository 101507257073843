import { Box } from '@mui/material';
import { useState } from 'react';
import useFetchApiData from '../../../core/hooks/useFetchApiData';
import Layout from '../../../core/ui/layout/Layout';
import TableContainer from '../../../core/ui/table/TableContainer';
import { TableHeader } from '../../../core/ui/table/tableTypes';
import { Paginated } from '../../../core/utils/types';
import { parseQuery } from '../../../core/utils/utility';
import { Vacancy } from '../vacancy';
import { useSendApiData } from '../../../core/hooks/useSendApiData';
import { toastError, toastMessage } from '../../../core/utils/ui/alert';

const tableHeaders: TableHeader[] = [
  { field: 'title', label: 'Title', align: 'left' },
  { field: 'min_experience', label: 'Min Experience', align: 'left' },
  { field: 'positions', label: 'Positions', align: 'left' },
  {
    field: 'published',
    label: 'Published',
    align: 'left',
    renderItem: (pub: boolean) => (pub ? 'Yes' : 'No'),
  },
  { field: 'deadline', label: 'Deadline', align: 'left' },
];
const VacancyList = () => {
  const [data, setData] = useState<Record<string, any>[]>([]);
  const { fetchData } = useFetchApiData();
  const { callApi } = useSendApiData();

  const handleFetchData = async (query: any, cb: (c: number) => void) => {
    await fetchData(`auth-xaea12/vacancies/fetch-all${parseQuery(query)}`, {
      onSuccess: ({ total, data: apiData }: Paginated<Vacancy>) => {
        setData(apiData);
        cb(total);
      },
    });
  };

  const handleDelete = async (id: number | string, showMessage = true) => {
    let success = false;

    await callApi({
      endpoint: `auth-xaea12/vacancies/${id}`,
      data: {},
      method: 'delete',
      onSuccess: async () => {
        if (showMessage) toastMessage('Item Removed');
        success = true;
      },
      onError: () => {
        if (showMessage)
          toastError('Something went wrong while deleting. Try again');
      },
    });

    return success;
  };

  return (
    <>
      <Layout>
        <Box sx={{ p: 2 }}>
          <TableContainer
            modelToken="vacancy"
            tableHeaders={tableHeaders}
            data={data}
            modelLabel="Vacancies"
            actions={{
              onFetchData: handleFetchData,
              onDelete: handleDelete,
            }}
            routes={{
              edit: '/vacancies/edit',
              create: '/vacancies/create',
              delete: '/vacancies',
              view: '/vacancies',
            }}
            settings={{
              canCreate: true,
              canEdit: true,
              canDelete: true,
              canViewItem: false,
            }}
          />
        </Box>
      </Layout>
    </>
  );
};

export default VacancyList;
