import {
  Collections,
  DoneOutline,
  Event as EventIcon,
  Feed,
  TrendingUp,
  ContactMail,
  Attachment,
  Work,
} from '@mui/icons-material';
import { Divider, Grid, List, Toolbar, Typography } from '@mui/material';
import { useLocation } from 'react-router-dom';
import SidebarLink from './SidebarLink';

const Sidebar = () => {
  const location = useLocation();

  return (
    <>
      <div>
        <Toolbar variant="dense" />
        <Divider />
        <List sx={{ p: 1 }}>
          <Grid
            container
            flexDirection="column"
            justifyContent="space-between"
            sx={{}}
          >
            <Grid item>
              <Typography variant="subtitle1" fontSize="12px" fontWeight="bold">
                COLLECTION TYPES
              </Typography>
              <SidebarLink
                to="/blogs"
                label="Blog"
                active={location.pathname.startsWith('/blogs')}
                icon={<Feed />}
              />
              <SidebarLink
                to="/causes"
                label="Cause"
                active={location.pathname.startsWith('/causes')}
              />
              <SidebarLink
                to="/events"
                label="Event"
                active={location.pathname.startsWith('/events')}
                icon={<EventIcon />}
              />
              <SidebarLink
                to="/galleries"
                label="Gallery"
                active={location.pathname.startsWith('/galleries')}
                icon={<Collections />}
              />
              <SidebarLink
                to="/resources"
                label="Resource"
                active={location.pathname.startsWith('/resources')}
                icon={<Attachment />}
              />
              <SidebarLink
                to="/success-stories"
                label="Success Story"
                active={location.pathname.startsWith('/success-stories')}
                icon={<TrendingUp />}
              />
              <SidebarLink
                to="/subscriptions"
                label="Subscription"
                active={location.pathname.startsWith('/subscriptions')}
                icon={<ContactMail />}
              />
              <SidebarLink
                to="/vacancies"
                label="Vacancy"
                active={location.pathname.startsWith('/vacancies')}
                icon={<Work />}
              />
            </Grid>

            <Grid item>
              <Typography
                variant="subtitle1"
                fontSize="12px"
                fontWeight="bold"
                sx={{ mt: 2 }}
              >
                SINGLE TYPES
              </Typography>
              <SidebarLink
                to="/impacts"
                label="Impact"
                active={location.pathname.startsWith('/impacts')}
                icon={<DoneOutline />}
              />
            </Grid>
          </Grid>
        </List>
      </div>
    </>
  );
};

export default Sidebar;
