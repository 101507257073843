import { Grid, Typography } from "@mui/material";
import { FormikHelpers } from "formik";
import { useParams } from "react-router-dom";
import { useState, useEffect } from "react";
import Layout from "../../../core/ui/layout/Layout";
import { useSendApiData } from "../../../core/hooks/useSendApiData";
import { toastError, toastMessage } from "../../../core/utils/ui/alert";
import { parseValidationErrors } from "../../../core/utils/validation";
import useFetchApiData from "../../../core/hooks/useFetchApiData";
import Loader from "../../../core/ui/utility/Loader";
import { ResourceEdit, Resource } from "../resource";
import EditResourceForm from "../components/EditResourceForm";
import ReturnButton from "../../../core/ui/utility/ReturnButton";
import { parseFormQuery } from "../../../core/utils/utility";

const EditResourceContainer = () => {
  const { id } = useParams();
  const { callApi, loading: submitting } = useSendApiData();
  const { fetchData, loading } = useFetchApiData();
  const [resource, setResource] = useState<Resource | null>(null);

  const fetchResource = () =>
    fetchData(`resources/${id}`, {
      onSuccess: (data: Resource) => {
        setResource(data);
      },
    });

  useEffect(() => {
    fetchResource();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleSubmit = async (
    values: ResourceEdit,
    { setFieldError }: FormikHelpers<ResourceEdit>
  ) => {
    const formData: any = parseFormQuery(values, [])

    await callApi({
      endpoint: `auth-xaea12/resources/${id}?_method=PATCH`,
      method: 'post',
      data: formData,
      headers: {
        'Content-Type': 'multipart/form-data'
      },
      onValidationError: (err) => parseValidationErrors(err, setFieldError),
      onError: toastError,
      onSuccess: async (_) => {
        await fetchResource();
        toastMessage('Resource Edited');
      },
    });
  };

  return (
    <Layout renderLeftToolbar={() => <ReturnButton to="/resources" />}>
      <Loader loading={loading || !resource}>
        <Grid sx={{ p: 2 }}>  
          <Grid container sx={{ mb: 1, px: 1 }}>
            <Typography variant="h5">Edit Resource</Typography>
          </Grid>
          <EditResourceForm resource={resource!} onSubmit={handleSubmit} submitting={submitting} />
        </Grid>
      </Loader>
    </Layout>
  );
};

export default EditResourceContainer;
