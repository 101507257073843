
import { FormikHelpers, useFormik } from "formik";
import { useNavigate } from "react-router-dom";
import { EventCreate } from "../event";
import { Grid, TextField, FormHelperText, Typography, Paper, Box, Button } from "@mui/material";
import DateTimePicker from "react-datetime-picker";


type EventProps = {
  onSubmit: (
    value: EventCreate,
    helpers: FormikHelpers<EventCreate>
  ) => Promise<boolean>;
  submitting: boolean;
  
};

const CreateEventForm = ({
  onSubmit,
  submitting,
  
}: EventProps) => {
  const navigate = useNavigate();

  const handleSubmit = async (
    value: EventCreate,
    helpers: FormikHelpers<EventCreate>
  ) => {
    const success = await onSubmit(value, helpers);
    if (success) {
      navigate('/', { replace: true });
      navigate('/events/create', { replace: true });
    };
  };

  const initialValues: EventCreate =  {    title: '',
    description: '',
    event_date: new Date(),
    start_time: '',
    end_time: '',
}

  const formik = useFormik({
    initialValues,
    onSubmit: handleSubmit,
  });

  // console.log('Edit Form', initialValues);

  return (
    
<>
  <Box sx={{ flexGrow: 1 }}>
  <form onSubmit={formik.handleSubmit}>
    <Grid container spacing={1}>
    
<Grid item xs={12} sm={9}>
  <Paper sx={{ p: 2, pb: 3 }}>
  <Grid container justifyContent="space-between" spacing={1} sx={{ mt: 2 }}>
<Grid item alignSelf="flex-start" sx={{ flexGrow: 1 }}>
  <TextField
    fullWidth
    name="title"
    size="small"
    type="text"
    variant="outlined"
    label="Title"
    placeholder=""
    value={formik.values.title}
    onChange={formik.handleChange}
    error={Boolean(formik.errors.title)}
    helperText={formik.errors.title}
  />
</Grid>

<Grid item sx={{ flexGrow: 1 }}></Grid>
</Grid><Grid container justifyContent="space-between" spacing={1} sx={{ mt: 2 }}>
<Grid item alignSelf="flex-start" sx={{ flexGrow: 1 }}>
  <TextField
    fullWidth
    name="description"
    size="small"
    type="text"
    variant="outlined"
    label="Description"
    placeholder=""
    value={formik.values.description}
    onChange={formik.handleChange}
    error={Boolean(formik.errors.description)}
    helperText={formik.errors.description}
    multiline
    rows={4}
  />
</Grid>
</Grid><Grid container justifyContent="space-between" spacing={1} sx={{ mt: 2 }}>
<Grid
  item
  sx={{
    flexGrow: 1,
    padding: '0 !important',
  }}
  display="flex"
  flexDirection="column"
  alignItems="flex-start"
  justifyContent="center"
>
  <div
    style={{
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'flex-end',
      paddingLeft: '8px',
    }}
  >
    <Typography sx={{ p: 0, mr: 1 }}>Event Date</Typography>
    <Grid item alignItems="flex-end">
      <DateTimePicker
        onChange={(e) => {
          formik.setFieldValue('event_date', e);
        }}
        value={formik.values.event_date || new Date()}
        disableClock
        format="y-MM-dd"
      />
    </Grid>
  </div>
  {formik.touched.event_date && formik.errors.event_date && (
    <FormHelperText error>
      {formik.errors.event_date}
    </FormHelperText>
  )}
</Grid>

<Grid item alignSelf="flex-start" sx={{ flexGrow: 1 }}>
  <TextField
    fullWidth
    name="start_time"
    size="small"
    type="text"
    variant="outlined"
    label="Start Time"
    placeholder=""
    value={formik.values.start_time}
    onChange={formik.handleChange}
    error={Boolean(formik.errors.start_time)}
    helperText={formik.errors.start_time}
  />
</Grid>

<Grid item alignSelf="flex-start" sx={{ flexGrow: 1 }}>
  <TextField
    fullWidth
    name="end_time"
    size="small"
    type="text"
    variant="outlined"
    label="End Time"
    placeholder=""
    value={formik.values.end_time}
    onChange={formik.handleChange}
    error={Boolean(formik.errors.end_time)}
    helperText={formik.errors.end_time}
  />
</Grid>
</Grid>
  </Paper>
</Grid>

    
<Grid item xs={12} sm={3}>
  <Paper sx={{ p: 2 }}>
    <Grid container>
      <Button
        type="submit"
        variant="contained"
        disabled={submitting}
        size="small"
      >
        {submitting ? 'Saving' : 'Save'}
      </Button>
    </Grid>
  </Paper>
</Grid>

    </Grid>
    </form>
  </Box>
</>
  
  );
};

export default CreateEventForm;
