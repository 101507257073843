import { FormikHelpers, useFormik } from 'formik';
import { useNavigate } from 'react-router-dom';
import { VacancyCreate } from '../vacancy';
import {
  Grid,
  TextField,
  FormHelperText,
  Typography,
  FormControl,
  FormControlLabel,
  Checkbox,
  Paper,
  Box,
  Button,
} from '@mui/material';
import DateTimePicker from 'react-datetime-picker';

type VacancyProps = {
  onSubmit: (
    value: VacancyCreate,
    helpers: FormikHelpers<VacancyCreate>
  ) => Promise<boolean>;
  submitting: boolean;
};

const CreateVacancyForm = ({ onSubmit, submitting }: VacancyProps) => {
  const navigate = useNavigate();

  const handleSubmit = async (
    value: VacancyCreate,
    helpers: FormikHelpers<VacancyCreate>
  ) => {
    const success = await onSubmit(value, helpers);
    if (success) {
      navigate('/', { replace: true });
      navigate('/vacancies/create', { replace: true });
    }
  };

  const initialValues: VacancyCreate = {
    title: '',
    deadline: new Date(),
    description: '',
    min_experience: '',
    positions: 0,
    published: false,
  };

  const formik = useFormik({
    initialValues,
    onSubmit: handleSubmit,
  });

  // console.log('Edit Form', initialValues);

  return (
    <>
      <Box sx={{ flexGrow: 1 }}>
        <form onSubmit={formik.handleSubmit}>
          <Grid container spacing={1}>
            <Grid item xs={12} sm={9}>
              <Paper sx={{ p: 2, pb: 3 }}>
                <Grid
                  container
                  justifyContent="space-between"
                  spacing={1}
                  sx={{ mt: 2 }}
                >
                  <Grid item alignSelf="flex-start" sx={{ flexGrow: 1 }}>
                    <TextField
                      fullWidth
                      name="title"
                      size="small"
                      type="text"
                      variant="outlined"
                      label="Title"
                      placeholder=""
                      value={formik.values.title}
                      onChange={formik.handleChange}
                      error={Boolean(formik.errors.title)}
                      helperText={formik.errors.title}
                    />
                  </Grid>

                  <Grid
                    item
                    sx={{
                      flexGrow: 1,
                      padding: '0 !important',
                    }}
                    display="flex"
                    flexDirection="column"
                    alignItems="flex-start"
                    justifyContent="center"
                  >
                    <div
                      style={{
                        display: 'flex',
                        flexDirection: 'row',
                        alignItems: 'flex-end',
                        paddingLeft: '8px',
                      }}
                    >
                      <Typography sx={{ p: 0, mr: 1 }}>Deadline</Typography>
                      <Grid item alignItems="flex-end">
                        <DateTimePicker
                          onChange={(e) => {
                            formik.setFieldValue('deadline', e);
                          }}
                          value={formik.values.deadline || new Date()}
                          disableClock
                          format="y-MM-dd"
                        />
                      </Grid>
                    </div>
                    {formik.touched.deadline && formik.errors.deadline && (
                      <FormHelperText error>
                        {formik.errors.deadline}
                      </FormHelperText>
                    )}
                  </Grid>
                </Grid>
                <Grid
                  container
                  justifyContent="space-between"
                  spacing={1}
                  sx={{ mt: 2 }}
                >
                  <Grid item alignSelf="flex-start" sx={{ flexGrow: 1 }}>
                    <TextField
                      fullWidth
                      name="description"
                      size="small"
                      type="text"
                      variant="outlined"
                      label="Description"
                      placeholder=""
                      value={formik.values.description}
                      onChange={formik.handleChange}
                      error={Boolean(formik.errors.description)}
                      helperText={formik.errors.description}
                      multiline
                      rows={4}
                    />
                  </Grid>
                </Grid>
                <Grid
                  container
                  justifyContent="space-between"
                  spacing={1}
                  sx={{ mt: 2 }}
                >
                  <Grid item alignSelf="flex-start" sx={{ flexGrow: 2 }}>
                    <TextField
                      fullWidth
                      name="min_experience"
                      size="small"
                      type="text"
                      variant="outlined"
                      label="Min Experience"
                      placeholder=""
                      value={formik.values.min_experience}
                      onChange={formik.handleChange}
                      error={Boolean(formik.errors.min_experience)}
                      helperText={formik.errors.min_experience}
                    />
                  </Grid>

                  <Grid item alignSelf="flex-start" sx={{ flexGrow: 2 }}>
                    <TextField
                      fullWidth
                      name="positions"
                      size="small"
                      type="number"
                      variant="outlined"
                      label="Positions"
                      placeholder=""
                      value={formik.values.positions}
                      onChange={formik.handleChange}
                      error={Boolean(formik.errors.positions)}
                      helperText={formik.errors.positions}
                    />
                  </Grid>

                  <Grid item alignSelf="flex-start" sx={{ flexGrow: 1 }}>
                    <FormControl error>
                      <FormControlLabel
                        control={
                          <Checkbox
                            name="published"
                            checked={formik.values.published}
                            onChange={formik.handleChange}
                          />
                        }
                        label="Published"
                      />
                      {Boolean(formik.errors.published) && (
                        <FormHelperText>
                          {formik.errors.published}
                        </FormHelperText>
                      )}
                    </FormControl>
                  </Grid>
                </Grid>
              </Paper>
            </Grid>

            <Grid item xs={12} sm={3}>
              <Paper sx={{ p: 2 }}>
                <Grid container>
                  <Button
                    type="submit"
                    variant="contained"
                    disabled={submitting}
                    size="small"
                  >
                    {submitting ? 'Saving' : 'Save'}
                  </Button>
                </Grid>
              </Paper>
            </Grid>
          </Grid>
        </form>
      </Box>
    </>
  );
};

export default CreateVacancyForm;
