import { Grid, Typography } from '@mui/material';
import { FormikHelpers } from 'formik';
import Layout from '../../../core/ui/layout/Layout';
import { useSendApiData } from '../../../core/hooks/useSendApiData';
import { toastError, toastMessage } from '../../../core/utils/ui/alert';
import { parseValidationErrors } from '../../../core/utils/validation';
import { ImpactCreate, Impact } from '../impact';
import CreateImpactForm from '../components/CreateImpactForm';
import { parseFormQuery } from '../../../core/utils/utility';
import { useState, useEffect } from 'react';
import useFetchApiData from '../../../core/hooks/useFetchApiData';
import Loader from '../../../core/ui/utility/Loader';

const CreateImpactContainer = () => {
  const { callApi, loading: submitting } = useSendApiData();
  const { fetchData, loading } = useFetchApiData();
  const [impact, setImpact] = useState<Impact | null>(null);

  const fetchImpact = () =>
    fetchData(`impacts`, {
      onSuccess: (data: Impact) => {
        if (data) {
          setImpact(data);
        }
      },
    });

  useEffect(() => {
    fetchImpact();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleSubmit = async (
    values: ImpactCreate,
    { setFieldError }: FormikHelpers<ImpactCreate>
  ) => {
    let success = false;

    const formData: any = parseFormQuery(values, []);

    await callApi({
      endpoint: 'auth-xaea12/impacts',
      data: formData,
      headers: {
        'Content-Type': 'application/json',
      },
      onValidationError: (err) => parseValidationErrors(err, setFieldError),
      onError: toastError,
      onSuccess: async (_) => {
        toastMessage('Impact Created');
        success = true;
      },
    });

    return success;
  };

  return (
    <Layout>
      <Loader loading={loading}>
        <Grid sx={{ p: 2 }}>
          <Grid container sx={{ mb: 2, px: 1 }}>
            <Typography variant="h5">
              {impact ? 'Edit' : 'Add'} Impact
            </Typography>
          </Grid>
          <CreateImpactForm
            impact={impact}
            onSubmit={handleSubmit}
            submitting={submitting}
          />
        </Grid>
      </Loader>
    </Layout>
  );
};

export default CreateImpactContainer;
