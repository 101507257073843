import { BrowserRouter, Routes, Route } from 'react-router-dom';
import PrivateRoute from '../../features/auth/containers/PrivateRoute';
import LoginPage from '../../features/auth/pages/LoginPage';
import ProfilePage from '../../features/auth/pages/ProfilePage';
import HomePage from '../../features/HomePage';
import ListEventPage from '../../features/event/pages/ListEventPage';
import CreateEventPage from '../../features/event/pages/CreateEventPage';
import EditEventPage from '../../features/event/pages/EditEventPage';
import ListBlogPage from '../../features/blog/pages/ListBlogPage';
import CreateBlogPage from '../../features/blog/pages/CreateBlogPage';
import EditBlogPage from '../../features/blog/pages/EditBlogPage';
import ListSuccessStoryPage from '../../features/successStory/pages/ListSuccessStoryPage';
import CreateSuccessStoryPage from '../../features/successStory/pages/CreateSuccessStoryPage';
import EditSuccessStoryPage from '../../features/successStory/pages/EditSuccessStoryPage';
import CreateImpactPage from '../../features/impact/pages/CreateImpactPage';
import ListGalleryPage from '../../features/gallery/pages/ListGalleryPage';
import CreateGalleryPage from '../../features/gallery/pages/CreateGalleryPage';
import EditGalleryPage from '../../features/gallery/pages/EditGalleryPage';
import ListSubscriptionPage from '../../features/subscription/pages/ListSubscriptionPage';
import ListCausePage from '../../features/cause/pages/ListCausePage';
import CreateCausePage from '../../features/cause/pages/CreateCausePage';
import EditCausePage from '../../features/cause/pages/EditCausePage';
import ListResourcePage from '../../features/resource/pages/ListResourcePage';
import CreateResourcePage from '../../features/resource/pages/CreateResourcePage';
import EditResourcePage from '../../features/resource/pages/EditResourcePage';
import ListVacancyPage from '../../features/vacancy/pages/ListVacancyPage';
import CreateVacancyPage from '../../features/vacancy/pages/CreateVacancyPage';
import EditVacancyPage from '../../features/vacancy/pages/EditVacancyPage';

const RouteManager = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/login" element={<LoginPage />} />
        <Route
          path="/"
          element={
            <PrivateRoute>
              <HomePage />
            </PrivateRoute>
          }
        />
        <Route
          path="/profile"
          element={
            <PrivateRoute>
              <ProfilePage />
            </PrivateRoute>
          }
        />

        {/* Event Routes  */}
        <Route
          path="/events"
          element={
            <PrivateRoute>
              <ListEventPage />
            </PrivateRoute>
          }
        />
        <Route
          path="/events/create"
          element={
            <PrivateRoute>
              <CreateEventPage />
            </PrivateRoute>
          }
        />
        <Route
          path="/events/edit/:id"
          element={
            <PrivateRoute>
              <EditEventPage />
            </PrivateRoute>
          }
        />

        {/* Blog ROutes  */}
        <Route
          path="/blogs"
          element={
            <PrivateRoute>
              <ListBlogPage />
            </PrivateRoute>
          }
        />
        <Route
          path="/blogs/create"
          element={
            <PrivateRoute>
              <CreateBlogPage />
            </PrivateRoute>
          }
        />
        <Route
          path="/blogs/edit/:id"
          element={
            <PrivateRoute>
              <EditBlogPage />
            </PrivateRoute>
          }
        />

        {/* Success Story Routes  */}
        <Route
          path="/success-stories"
          element={
            <PrivateRoute>
              <ListSuccessStoryPage />
            </PrivateRoute>
          }
        />
        <Route
          path="/success-stories/create"
          element={
            <PrivateRoute>
              <CreateSuccessStoryPage />
            </PrivateRoute>
          }
        />
        <Route
          path="/success-stories/edit/:id"
          element={
            <PrivateRoute>
              <EditSuccessStoryPage />
            </PrivateRoute>
          }
        />

        {/* Impact Routes  */}
        <Route
          path="/impacts"
          element={
            <PrivateRoute>
              <CreateImpactPage />
            </PrivateRoute>
          }
        />

        {/* Gallery Routes  */}
        <Route
          path="/galleries"
          element={
            <PrivateRoute>
              <ListGalleryPage />
            </PrivateRoute>
          }
        />
        <Route
          path="/galleries/create"
          element={
            <PrivateRoute>
              <CreateGalleryPage />
            </PrivateRoute>
          }
        />
        <Route
          path="/galleries/edit/:id"
          element={
            <PrivateRoute>
              <EditGalleryPage />
            </PrivateRoute>
          }
        />

        {/* Subscription Routes  */}
        <Route
          path="/subscriptions"
          element={
            <PrivateRoute>
              <ListSubscriptionPage />
            </PrivateRoute>
          }
        />

        {/* Cause  */}
        <Route
          path="/causes"
          element={
            <PrivateRoute>
              <ListCausePage />
            </PrivateRoute>
          }
        />
        <Route
          path="/causes/create"
          element={
            <PrivateRoute>
              <CreateCausePage />
            </PrivateRoute>
          }
        />
        <Route
          path="/causes/edit/:id"
          element={
            <PrivateRoute>
              <EditCausePage />
            </PrivateRoute>
          }
        />

        {/* Resource  */}
        <Route
          path="/resources"
          element={
            <PrivateRoute>
              <ListResourcePage />
            </PrivateRoute>
          }
        />
        <Route
          path="/resources/create"
          element={
            <PrivateRoute>
              <CreateResourcePage />
            </PrivateRoute>
          }
        />
        <Route
          path="/resources/edit/:id"
          element={
            <PrivateRoute>
              <EditResourcePage />
            </PrivateRoute>
          }
        />

        {/* Vacancy  */}
        <Route
          path="/vacancies"
          element={
            <PrivateRoute>
              <ListVacancyPage />
            </PrivateRoute>
          }
        />
        <Route
          path="/vacancies/create"
          element={
            <PrivateRoute>
              <CreateVacancyPage />
            </PrivateRoute>
          }
        />
        <Route
          path="/vacancies/edit/:id"
          element={
            <PrivateRoute>
              <EditVacancyPage />
            </PrivateRoute>
          }
        />

        <Route path="*" element={<>Not Found page</>} />
      </Routes>
    </BrowserRouter>
  );
};

export default RouteManager;
