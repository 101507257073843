import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material';
import { DateTime } from 'luxon';
import FileContainer from '../../../core/ui/utility/FileContainer';
import { JobApplication } from '../vacancy';

type JobListProps = {
  jobs: JobApplication[];
};

const JobList = ({ jobs }: JobListProps) => {
  return (
    <>
      <TableContainer
        component={Paper}
        sx={{ overflowX: 'auto', maxWidth: 1200, mt: 2 }}
      >
        <Table aria-label="custom pagination table" size="medium">
          <TableHead sx={{ background: 'rgb(243,243,242)' }}>
            <TableRow>
              <TableCell>#</TableCell>
              <TableCell>Name</TableCell>
              <TableCell>Phone</TableCell>
              <TableCell>Email</TableCell>
              <TableCell>Cv</TableCell>
              <TableCell>Date Applied</TableCell>
              <TableCell align="right" sx={{ width: 48 }}></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {jobs
              .sort((a, b) => a.first_name.localeCompare(b.first_name))
              .map((job, i) => (
                <TableRow key={job.id}>
                  <TableCell>{i + 1}</TableCell>
                  <TableCell size="small">
                    {job.first_name} {job.last_name}
                  </TableCell>
                  <TableCell>{job.phone}</TableCell>
                  <TableCell>{job.email}</TableCell>
                  <TableCell>
                    <FileContainer media={job.media[0]} />
                  </TableCell>
                  <TableCell component="th" scope="row">
                    {DateTime.fromISO(job.created_at).toLocaleString(
                      DateTime.DATE_MED
                    )}
                  </TableCell>
                  <TableCell align="right" sx={{ width: 48 }}></TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
};

export default JobList;
