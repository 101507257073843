import { FormikHelpers, useFormik } from 'formik';
import { useNavigate } from 'react-router-dom';
import { ImpactCreate, Impact } from '../impact';
import { Grid, TextField, Paper, Box, Button } from '@mui/material';

type ImpactProps = {
  onSubmit: (
    value: ImpactCreate,
    helpers: FormikHelpers<ImpactCreate>
  ) => Promise<boolean>;
  submitting: boolean;
  impact: Impact | null;
};

const CreateImpactForm = ({ onSubmit, submitting, impact }: ImpactProps) => {
  const navigate = useNavigate();

  const handleSubmit = async (
    value: ImpactCreate,
    helpers: FormikHelpers<ImpactCreate>
  ) => {
    const success = await onSubmit(value, helpers);
    if (success) {
      navigate('/', { replace: true });
      navigate('/impacts', { replace: true });
    }
  };

  const initialValues: ImpactCreate = impact
    ? {
        money: impact.money,
        successful_projects: impact.successful_projects,
        people_impacted: impact.people_impacted,
        total_volunteers: impact.total_volunteers,
      }
    : {
        money: '',
        successful_projects: 0,
        people_impacted: 0,
        total_volunteers: 0,
      };

  const formik = useFormik({
    initialValues,
    onSubmit: handleSubmit,
  });

  // console.log('Edit Form', initialValues);

  return (
    <>
      <Box sx={{ flexGrow: 1 }}>
        <form onSubmit={formik.handleSubmit}>
          <Grid container spacing={1}>
            <Grid item xs={12} sm={9}>
              <Paper sx={{ p: 2, pb: 3 }}>
                <Grid
                  container
                  justifyContent="space-between"
                  spacing={1}
                  sx={{ mt: 2 }}
                >
                  <Grid item alignSelf="flex-start" sx={{ flexGrow: 1 }}>
                    <TextField
                      fullWidth
                      name="money"
                      size="small"
                      type="text"
                      variant="outlined"
                      label="Money"
                      placeholder=""
                      value={formik.values.money}
                      onChange={formik.handleChange}
                      error={Boolean(formik.errors.money)}
                      helperText={formik.errors.money}
                    />
                  </Grid>

                  <Grid item sx={{ flexGrow: 1 }}></Grid>
                </Grid>
                <Grid
                  container
                  justifyContent="space-between"
                  spacing={1}
                  sx={{ mt: 2 }}
                >
                  <Grid item alignSelf="flex-start" sx={{ flexGrow: 1 }}>
                    <TextField
                      fullWidth
                      name="successful_projects"
                      size="small"
                      type="number"
                      variant="outlined"
                      label="Successful Projects"
                      placeholder=""
                      value={formik.values.successful_projects}
                      onChange={formik.handleChange}
                      error={Boolean(formik.errors.successful_projects)}
                      helperText={formik.errors.successful_projects}
                    />
                  </Grid>

                  <Grid item alignSelf="flex-start" sx={{ flexGrow: 1 }}>
                    <TextField
                      fullWidth
                      name="people_impacted"
                      size="small"
                      type="number"
                      variant="outlined"
                      label="People Impacted"
                      placeholder=""
                      value={formik.values.people_impacted}
                      onChange={formik.handleChange}
                      error={Boolean(formik.errors.people_impacted)}
                      helperText={formik.errors.people_impacted}
                    />
                  </Grid>

                  <Grid item alignSelf="flex-start" sx={{ flexGrow: 1 }}>
                    <TextField
                      fullWidth
                      name="total_volunteers"
                      size="small"
                      type="number"
                      variant="outlined"
                      label="Total Volunteers"
                      placeholder=""
                      value={formik.values.total_volunteers}
                      onChange={formik.handleChange}
                      error={Boolean(formik.errors.total_volunteers)}
                      helperText={formik.errors.total_volunteers}
                    />
                  </Grid>
                </Grid>
              </Paper>
            </Grid>

            <Grid item xs={12} sm={3}>
              <Paper sx={{ p: 2 }}>
                <Grid container>
                  <Button
                    type="submit"
                    variant="contained"
                    disabled={submitting}
                    size="small"
                  >
                    {submitting ? 'Saving' : 'Save'}
                  </Button>
                </Grid>
              </Paper>
            </Grid>
          </Grid>
        </form>
      </Box>
    </>
  );
};

export default CreateImpactForm;
