import { FormikHelpers, useFormik } from 'formik';
import { useNavigate } from 'react-router-dom';
import { CauseCreate } from '../cause';
import {
  Grid,
  TextField,
  FormHelperText,
  Typography,
  Box,
  Paper,
  Button,
} from '@mui/material';
import DateTimePicker from 'react-datetime-picker';
import { AddCircleOutline, RemoveCircleOutline } from '@mui/icons-material';
import ReactQuill from 'react-quill';
import { richTextModules } from '../../../core/utils/utility';

type CauseProps = {
  onSubmit: (
    value: CauseCreate,
    helpers: FormikHelpers<CauseCreate>
  ) => Promise<boolean>;
  submitting: boolean;
};

const CreateCauseForm = ({ onSubmit, submitting }: CauseProps) => {
  const navigate = useNavigate();

  const handleSubmit = async (
    value: CauseCreate,
    helpers: FormikHelpers<CauseCreate>
  ) => {
    const success = await onSubmit(value, helpers);
    if (success) {
      navigate('/', { replace: true });
      navigate('/causes/create', { replace: true });
    }
  };

  const initialValues: CauseCreate = {
    title: '',
    program_goal: '',
    deadline: new Date(),
    description: '',
    content: '',
    objectives: [],
    image: null,
  };

  const formik = useFormik({
    initialValues,
    onSubmit: handleSubmit,
  });

  // console.log('Edit Form', initialValues);

  return (
    <>
      <Box sx={{ flexGrow: 1 }}>
        <form onSubmit={formik.handleSubmit}>
          <Grid container spacing={1}>
            <Grid item xs={12} sm={9}>
              <Paper sx={{ p: 2, pb: 3 }}>
                <Grid
                  container
                  justifyContent="space-between"
                  spacing={1}
                  sx={{ mt: 2 }}
                >
                  <Grid item alignSelf="flex-start" sx={{ flexGrow: 1 }}>
                    <TextField
                      fullWidth
                      name="title"
                      size="small"
                      type="text"
                      variant="outlined"
                      label="Title"
                      placeholder=""
                      value={formik.values.title}
                      onChange={formik.handleChange}
                      error={Boolean(formik.errors.title)}
                      helperText={formik.errors.title}
                    />
                  </Grid>

                  <Grid item alignSelf="flex-start" sx={{ flexGrow: 1 }}>
                    <TextField
                      fullWidth
                      name="program_goal"
                      size="small"
                      type="text"
                      variant="outlined"
                      label="Program Goal"
                      placeholder=""
                      value={formik.values.program_goal}
                      onChange={formik.handleChange}
                      error={Boolean(formik.errors.program_goal)}
                      helperText={formik.errors.program_goal}
                    />
                  </Grid>

                  <Grid
                    item
                    sx={{
                      flexGrow: 1,
                      padding: '0 !important',
                    }}
                    display="flex"
                    flexDirection="column"
                    alignItems="flex-start"
                    justifyContent="center"
                  >
                    <div
                      style={{
                        display: 'flex',
                        flexDirection: 'row',
                        alignItems: 'flex-end',
                        paddingLeft: '8px',
                      }}
                    >
                      <Typography sx={{ p: 0, mr: 1 }}>Deadline</Typography>
                      <Grid item alignItems="flex-end">
                        <DateTimePicker
                          onChange={(e) => {
                            formik.setFieldValue('deadline', e);
                          }}
                          value={formik.values.deadline || new Date()}
                          disableClock
                          format="y-MM-dd"
                        />
                      </Grid>
                    </div>
                    {formik.touched.deadline && formik.errors.deadline && (
                      <FormHelperText error>
                        {formik.errors.deadline}
                      </FormHelperText>
                    )}
                  </Grid>
                </Grid>
                <Grid
                  container
                  justifyContent="space-between"
                  spacing={1}
                  sx={{ mt: 2 }}
                >
                  <Grid item alignSelf="flex-start" sx={{ flexGrow: 1 }}>
                    <TextField
                      fullWidth
                      name="description"
                      size="small"
                      type="text"
                      variant="outlined"
                      label="Description"
                      placeholder=""
                      value={formik.values.description}
                      onChange={formik.handleChange}
                      error={Boolean(formik.errors.description)}
                      helperText={formik.errors.description}
                      multiline
                      rows={4}
                    />
                  </Grid>
                </Grid>

                <Grid
                  container
                  justifyContent="space-between"
                  spacing={1}
                  sx={{ mt: 2 }}
                >
                  <Grid item alignSelf="flex-start" sx={{ flexGrow: 1 }}>
                    <Grid item xs={12}>
                      Content
                    </Grid>
                    <ReactQuill
                      modules={richTextModules}
                      value={formik.values.content}
                      onChange={(e) => {
                        formik.setFieldValue('content', e);
                      }}
                      placeholder=""
                    />
                    {formik.touched.content && formik.errors.content && (
                      <FormHelperText error>
                        {formik.errors.content}
                      </FormHelperText>
                    )}
                  </Grid>
                </Grid>
                <Grid
                  container
                  justifyContent="space-between"
                  spacing={1}
                  sx={{ mt: 2 }}
                >
                  <Grid item alignSelf="flex-start" sx={{ flexGrow: 1 }}>
                    <Grid
                      item
                      display="flex"
                      justifyContent="space-between"
                      flexDirection="row"
                    >
                      <Typography>Objectives</Typography>

                      <AddCircleOutline
                        fontSize="small"
                        sx={{ ml: 1, alignSelf: 'center' }}
                        color="primary"
                        onClick={() => {
                          formik.setFieldValue('objectives', [
                            ...formik.values.objectives,
                            '',
                          ]);
                        }}
                      />
                    </Grid>

                    {(formik.values.objectives || []).map((objective, i) => (
                      <Grid
                        item
                        key={`objective${i}`}
                        sx={{ mt: 1 }}
                        display="flex"
                        flexDirection="row"
                      >
                        <TextField
                          fullWidth
                          name={`objectives[${i}]`}
                          size="small"
                          type="text"
                          variant="outlined"
                          placeholder="Objective"
                          value={formik.values.objectives[i]}
                          onChange={formik.handleChange}
                          error={Boolean(formik.errors.objectives?.[i])}
                          helperText={formik.errors.objectives}
                        />
                        <RemoveCircleOutline
                          fontSize="small"
                          sx={{ mx: 1, alignSelf: 'center' }}
                          color="warning"
                          onClick={() => {
                            const curObjectives = [...formik.values.objectives];
                            curObjectives.splice(i, 1);
                            formik.setFieldValue('objectives', curObjectives);
                          }}
                        />
                      </Grid>
                    ))}
                    {formik.touched.objectives && formik.errors.objectives && (
                      <FormHelperText error>
                        {formik.errors.objectives}
                      </FormHelperText>
                    )}
                  </Grid>

                  <Grid item alignSelf="flex-start" sx={{ flexGrow: 1 }}>
                    <Grid item xs={12}>
                      Image
                    </Grid>
                    <Box>
                      <input
                        type="file"
                        onChange={({ target: { files } }) => {
                          formik.setFieldValue(
                            'image',
                            files && files.length ? files[0] : null
                          );
                        }}
                        name="image"
                      />
                    </Box>
                    {formik.touched.image && formik.errors.image && (
                      <FormHelperText error>
                        {formik.errors.image}
                      </FormHelperText>
                    )}
                  </Grid>
                </Grid>
              </Paper>
            </Grid>

            <Grid item xs={12} sm={3}>
              <Paper sx={{ p: 2 }}>
                <Grid container>
                  <Button
                    type="submit"
                    variant="contained"
                    disabled={submitting}
                    size="small"
                  >
                    {submitting ? 'Saving' : 'Save'}
                  </Button>
                </Grid>
              </Paper>
            </Grid>
          </Grid>
        </form>
      </Box>
    </>
  );
};

export default CreateCauseForm;
